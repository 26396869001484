
    import dynamic from "next/dynamic"
    const AsyncBoundary = dynamic(() => import("./_app.tsx?hasBoundary"), {suspense:true});
    export default AsyncBoundary;
    

    export const getServerSideProps = async (ctx) => {
      return import("./_app.tsx?hasBoundary").then((mod) => {
        return mod.getServerSideProps(ctx)
      })
    }
    